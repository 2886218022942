<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách chi nhánh</h3>
            </div>
            <div class="card-title">
                <el-button class="btn btn-primary" @click="showImport">
                    Upload KPI
                </el-button>
            </div>
        </div>
        <div class="card-body">
            <div class="example mb-10">
                <div class="form-group col-md-12">
                    <div class="input-group">
                        <div class="col-md-3">
                            <label for="">Giám đốc văn phòng</label>
                            <input type="text" class="form-control" placeholder="Nhập ID, Tên giám GDVP"
                                   v-model="query.user"
                                   @input="search"
                                   aria-describedby="basic-addon2">
                        </div>
                        <div class="col-md-3">
                            <label for="">Tên văn phòng</label>
                            <input type="text" class="form-control" placeholder="Nhập ID, Tên văn phòng"
                                   v-model="query.branch"
                                   @input="search"
                                   aria-describedby="basic-addon2">
                        </div>
                        <div class="col-md-3">
                            <label for="">Thời gian</label>
                            <el-date-picker
                                v-model="query.date_range"
                                type="date"
                                class="w-100"
                                format="dd-MM-yyyy"
                                value-format="dd-MM-yyyy"
                                id="expiration_date"
                                @input="datesSet($event)"
                                placeholder="Chọn thời gian">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="example-preview table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                        <tr>
                            <th>ID Văn phòng</th>
                            <th>Tên văn phòng</th>
                            <th>Mã GĐVP</th>
                            <th>Giám đốc văn phòng</th>
                            <th>Chỉ tiêu</th>
                            <th>Thời gian</th>
                            <th>Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dataKpi" :key="index">
                            <td>{{ item.branch_id }}</td>
                            <td>{{ item.branch ? (item.branch.name_alias ?? item.branch.name) : '' }}</td>
                            <td>{{ item.user_id + 100000 }}</td>
                            <td>{{ item.user ? item.user.name : '' }}</td>
                            <td>{{ item.target }}</td>
                            <td>{{ item.month + '-' + item.year }}</td>
                            <td>
                                <a
                                   title="Xoá"
                                   @click="deleteKpi(item.id)"
                                   href="javascript:"
                                   class="btn btn-xs btn-icon btn-outline-danger ml-2"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center edu-paginate mx-auto text-center">
                <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
                >
                </paginate>
            </div>
        </div>
        <el-dialog title="Upload KPI văn phòng" :visible.sync="dialogImport">
            <div class="mb-2">
                <div>
                    <p for="">Bước 1: Tải file mẫu</p>
                    <div>
                        <el-button class="btn btn-primary" @click="exportFileMau">
                            <i class="el-icon-download"></i>
                            Tải xuống
                        </el-button>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <p for="">Bước 2: Tải file excel lên hệ thống</p>
                    <div class="mb-2">
                        <el-upload
                            ref="upload"
                            accept=".xlsx"
                            :limit="1"
                            list-type="text"
                            action=""
                            :file-list="fileList"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :multiple="false"
                        >
                            <el-button size="small" type="warning">
                                <i class="el-icon-upload2"></i>
                                Tải lên
                            </el-button>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelImport">Cancel</el-button>
                <el-button :disabled="disable" :loading="loading" type="success" class="btn btn-success"
                           @click="submitUpload">Xác nhận
                </el-button>
            </div>
        </el-dialog>

<!--        preview-->

        <el-dialog title="Lỗi upload KPI văn phòng" :visible.sync="logImport">
            <div>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</div>
            <table class="table" v-loading="loadingImport">
                <thead>
                <tr>
                    <th>Năm</th>
                    <th>Tháng</th>
                    <th>ID văn phòng</th>
                    <th>Mã nhân sự GĐVP</th>
                    <th>Chỉ tiêu</th>
                    <th>Lỗi</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(item, index) in listDataImport" :key="index">
                    <td>{{ item.nam }}</td>
                    <td>{{ item.thang }}</td>
                    <td>{{ item.idvanphong }}</td>
                    <td>{{ item.manhansugvp }}</td>
                    <td>{{ item.chitieu }}</td>
                    <td>
                        <p v-for="(item_prop, index_prop) in item?.error" :key="index_prop">
                            <span class="text-danger">
                                {{ item_prop }}
                            </span>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <div slot="footer" class="dialog-footer">
                <el-button v-loading="loadingImport" @click="dialogImport = true, logImport= false">Quay lại </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    DELETE_KPI_OFFICE,
    EXPORT_FILE_DEFAULT_KPI_OFFICE,
    GET_KPI_OFFICE,
    IMPORT_EXCEL_KPI_OFFICE
} from "../../../core/services/store/center/branch.module";
import {EXPORT_FILE_DEFAULT_TEST_SCORE, IMPORT_EXAM} from "../../../core/services/store/exam/exam.module";
import {read, utils} from "xlsx";
import error from "@/view/pages/error/Error";

export default {
    name: "KpiOfficeIndex",
    data() {
        return {
            query: {
                user: '',
                branch: '',
                date_range: ''
            },
            dataKpi: [],
            page: 1,
            last_page: 1,
            dialogImport: false,
            fileList: [],
            loading: false,
            disable: false,
            logImport: false,
            loadingImport: false,
            listDataImport: []
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        search() {
            this.pushParamsUrl();
            this.getList();
        },
        getList()
        {
            let payload = this.query;
            payload.page = this.page;
            this.$store.dispatch(GET_KPI_OFFICE, payload).then((res) => {
                this.dataKpi = res.data.data;
                this.last_page = res.data.last_page
            });
        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        deleteKpi(id)
        {
            this.$store.dispatch(DELETE_KPI_OFFICE, id).then((res) => {
                if (res.data)
                {
                    this.$bvToast.toast('Xoá kpi thành công', {
                        title: 'Xoá thành công',
                        variant: 'success',
                        solid: true
                    });
                    this.getList();
                    return;
                }
                this.$bvToast.toast(res.message, {
                    title: 'Xoá',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        clickCallback(obj) {
            this.page = obj
            this.$router.push({name: 'list-kpi-van-phong', query: {page: this.page}})
            this.getList()
        },
        exportFileMau() {
            this.$store.dispatch(EXPORT_FILE_DEFAULT_KPI_OFFICE).then((res) => {
                this.$notify({
                    title: 'Thành công',
                    message: res.message,
                    type: 'success'
                })
            }).catch((error) => {
                this.$notify({
                    title: 'Thất bại',
                    message: error.data.message,
                    type: 'error'
                })
            })
        },
        showImport() {
            this.dialogImport = true;
        },
        handleChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove() {
            this.fileList = []
        },
        cancelImport()
        {
            this.fileList = []
            this.dialogImport =false;
        },
        submitUpload() {
            if (!this.fileList.length) {
                this.$notify({
                    title: 'Thất bại',
                    message: 'Chưa có file nào được chọn',
                    type: 'error'
                })
            }
            this.fileToJson(this.fileList).then(dataToImport => {
                this.listDataImport = dataToImport;
                this.logImport = true;
                this.loadingImport = true;

                const toSlug = (str) => {
                    return str
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .replace(/\s+/g, "_")
                        .replace(/[^a-z0-9-]/g, "");
                };
                const convertKeysToSlug = (obj) => {
                    return Object.fromEntries(
                        Object.entries(obj).map(([key, value]) => [toSlug(key), value])
                    );
                };
                const convertedData = this.listDataImport.map(convertKeysToSlug);
                this.validateData(convertedData);
                this.listDataImport = convertedData;
                this.$store.dispatch(IMPORT_EXCEL_KPI_OFFICE, convertedData).then((res) => {
                    if (res.data && res.data.dataError && res.data.dataError.length) {
                        this.listDataImport = res.data.dataError;
                        this.$notify({
                            title: 'Không Thành công',
                            message: res.message,
                            type: 'error'
                        })
                        this.loadingImport = false;

                        return;
                    }
                    if(res.data) {
                        this.dialogImport = false;
                        this.logImport = false;
                        this.$notify({
                            title: 'Thành công',
                            message: res.message,
                            type: 'success'
                        })
                        this.fileList = []
                        this.loadingImport = false;
                        return;
                    }

                })
            })
        },
        fileToJson(file) {
            return new Promise((res, rej) => {
                /* Boilerplate to set up FileReader */
                const reader = new FileReader()
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result
                    const wb = read(bstr, {type: 'binary'})
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws)

                    res(data);
                }
                reader.onerror = (e) => {
                    rej(e);
                };
                reader.readAsBinaryString(file[0].raw)
            });
        },

        validateData(dataToImport) {
            dataToImport.map((data) => {
                return data.error = [
                    typeof data.nam == 'undefined' ? 'Năm không được để trống !' : '',
                    typeof data.thang == 'undefined' ? 'Tháng không được để trống !' : '',
                    typeof data.idvanphong == 'undefined' ? 'ID văn phòng không được để trống !' : '',
                    typeof data.chitieu == 'undefined' ? 'Chỉ tiêu không được để trống !' : '',
                    typeof data.nam != 'number' && data.nam != null ? 'Năm không đúng định dạng số.' : '',
                    typeof data.thang != 'number' && data.thang != null ? 'Tháng không đúng định dạng số.' : '',
                ];
            });
        },
        datesSet(event)
        {
            this.search();
        }
    }
}
</script>

<style scoped>

</style>